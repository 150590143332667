@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.fieldGroup {
  margin: 0;
  padding: 0;
  border: none;
}

.fieldGroupPlain {
  margin: 0;
  padding-left: 12px;
  padding-bottom: 6px;
  border: none;

  @media (--viewportMedium) {
    padding: 3px 0 29px 12px;
    padding: 4px 0 4px 12px;
  }
}

.list {
  margin: 0;
}

.item {
  padding: 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 4px 0;
  }
}

.categoryFIlterSection{
  padding:10px 36px 6px 36px;
}
.categoryFIlterSection{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.category{
  display: flex;
  border: #ededed solid 1px;
  gap: 7px;
  padding: 2px 12px 2px 3px;
  border-radius: 35px;
  background: #FFF;
  box-shadow:#ededed 0px 2px 2px 2px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.category:hover{
  background: #042f67;
  box-shadow: #ededed 0px 2px 0px 0px;
  color: #FFF;
  transition: 0.5s;
}
.titleCategory{
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.imageBlock{
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.headingContainer{
  padding: 0 36px;

  & h2{
    margin: 8px 0 0 0;
    font-size: 35px;
  }
  & p {
    margin: 0;
  }
  & a{
    color:#a40000;
  }
}
.clearFilters{
  position: absolute;
    background: #F44336;
    width: 22px;
    height: 22px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    line-height: 22px;
    color: #FFF;
    margin-top: -12px;
}

.image {
  margin: 10px;
  display: inline-block;
}
.imageContainer {
  vertical-align: middle;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  display: flex;
}
.prev, .next {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
  cursor: pointer;
  color: black;
  transition: 0.6s ease;
  font-size: 12px;
}
.next {
  margin-left: 5px;
  text-transform: none;
  background: #FFF;
  padding: 0;
  border-radius: 50px;
  padding: 0;
  border: 0.5px solid rgb(0 0 0 / 0.3);
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.next:hover{
  box-shadow: #5151511f 0px 0px 3px 3px;
}
.prev {
  margin-right: 5px;
  text-transform: none;
  background: #FFF;
  padding: 0;
  border-radius: 50px;
  padding: 0;
  border: 0.5px solid rgb(0 0 0 / 0.3);
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.prev:hover{
  box-shadow: #5151511f 0px 0px 3px 3px;
}
.prev:hover, .next:hover {
  color: #0c1345;
  height: 100%;
}
.chipSection{
  display: grid;
}
.categoryScroll{
  display: flex;
  gap: 10px;
  align-items: center;
}